html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

b {
  color: #3e3b3f;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 20px;
  background-color: #fff;
  color: #3e3b3f;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove outline from focusable elements for mouse users only */
:focus:not(:focus-visible) {
  outline: none;
}

img {
  display: block;
}


/* Make sure the elements are rendered consistently */
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

a:focus {
  outline: none;
}

/* Navigation */

.navbar {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 8px; /* Adding some border-radius for smooth corners */
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  max-height: 50px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.link {
  margin: 0 15px;
  color: #7a7a7a;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px; /* Adding border-radius for hover effect */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.link i {
  margin-right: 5px;
}

.link:hover {
  color: #fff;
  background: linear-gradient(45deg, #921cbc, #34057b);
}

.link:active {
  color: #fff;
  background-color: #490a7d;
}


.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.content-overlay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  gap: 30px;
  width: 100%;
}

.text-content {
  max-width: 50%;
}

.main-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.3;
  color: #1b1b29;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 24px;
  color: #666;
  margin-bottom: 30px;
  letter-spacing: 1.4px;
}

.title {
  font-size: 40px;
  color: #1b1b29;
  font-weight: 700;
  margin-bottom: 50px;
}

.main-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background: linear-gradient(45deg, #921cbc, #34057b);
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.main-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0) 40%);
  transition: all 0.5s ease;
}

.main-button:hover::after {
  left: 100%;
}

.main-button:hover {
  background: linear-gradient(45deg, #34057b, #921cbc);
  transform: scale(1.05);
}

.image-content img {
  width: 650px;
  height: auto;
}

.info-section {
  background: linear-gradient(45deg, #921cbc, #34057b);
  padding: 40px 20px;
  border-radius: 15px;
  color: white;
  max-width: 1200px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.info-text {
  max-width: 50%;
}

.info-title {
  padding: 20px;
  font-size: 40px;
  font-weight: 700;
  max-width: 50%;
  line-height: 1.38;
}

.info-description {
  font-size: 20px;
  line-height: 1.6;

}

.goals-section {
  padding: 50px 0px;
  color: white;
  max-width: 1240px;
  margin: 0 auto;
  text-align: center;
}

.goals-section h2 {
  font-size: 48px;
  color: #1b1b29;
  font-weight: 700;
  margin-bottom: 50px;
}

.goals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.goal-item {
  background: linear-gradient(45deg, #921cbc, #34057b);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.goal-icon {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.goal-icon img {
  width: 40px;
  height: 40px;
}

.goal-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.goal-item p {
  font-size: 16px;
  line-height: 1.6;
}

.contact-form-section {
  padding: 40px 20px;
  border-radius: 15px;
  max-width: 1200px;
  margin: 50px auto;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-section h2 {
  font-size: 36px;
  font-weight: 700;
  color: #1b1b29;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.form-group {
  width: 100%;
  max-width: 500px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  color: #1b1b29;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #ddd;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
  border-color: #921cbc;
  box-shadow: 0 0 8px rgba(146, 28, 188, 0.5);
  outline: none;
}

.submit-button {
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background: linear-gradient(45deg, #921cbc, #34057b);
  text-decoration: none;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: linear-gradient(45deg, #34057b, #921cbc);
  transform: scale(1.05);
}

.thank-you-message {
  color: #1b1b29;
  text-align: center;
  margin-top: 20px;
}

.thank-you-message h3 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.thank-you-message p {
  font-size: 18px;
  line-height: 1.6;
}




/* AboutUs  */

.criteria-section {
  padding: 50px 20px;
  background: linear-gradient(45deg, #921cbc, #34057b);
  border-radius: 15px;
  color: white;
  max-width: 1200px;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.criteria-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
}

.criteria-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.criteria-item {
  background: white;
  color: #1b1b29;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.criteria-icon {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.criteria-icon img {
  width: 40px;
  height: 40px;
}

.criteria-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.criteria-item p {
  font-size: 16px;
  line-height: 1.6;
}

.about-block-text {
  max-width: 1200px;
  margin: 50px auto;
  text-align: center;
}

.professions-section {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 50px auto;
  text-align: start;
}

.professions-title {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1b1b29;
  text-align: center;
}

.professions-list {
  list-style: none;
  padding: 0;
  text-align: left;
}

.professions-list li {
  font-size: 22px;
  letter-spacing: 1.4px;
  line-height: 1.6;
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
}

.professions-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #921cbc;
  border-radius: 50%;
}

/* Documents */

.documents-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1240px;
  margin: 50px auto;
}

.documents-wrapper h2 {
  font-size: 48px;
    color: #1b1b29;
    font-weight: 700;
    text-align: center;
}

.document-block {
  display: flex;
  align-items: center;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.document-sub-block {
  display: flex;
  align-items: center;
  padding: 0px 40px;
}

.document-image {
  width: 540px;
  height: auto;
  margin-right: 40px;
}

.document-text {
  flex-grow: 1;
}

.document-title {
  font-size: 48px;
  font-weight: 700;
  color: #1b1b29;
  margin-bottom: 20px;
}

/* Ethics Code */

.codex-wrapper {
  padding: 50px 0px;
  max-width: 1200px;
  margin: 50px auto;
}

.codex-title {
  font-size: 48px;
  font-weight: 700;
  color: #1b1b29;
  text-align: center;
  margin-bottom: 30px;
}

.codex-section {
  margin-bottom: 30px;
}

.codex-subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #1b1b29;
  margin-bottom: 20px;
}

.codex-text {
  font-size: 20px;
  line-height: 1.6;
  color: #3e3b3f;
  padding-bottom: 20px;
}

.codex-list {
  list-style: none;
  padding: 0;
  padding-bottom: 20px;
}

.codex-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.6;
}

.codex-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #921cbc;
  border-radius: 50%;
}

.recipients-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0px 0px 50px 0px;
  font-size: 18px;
  text-align: left;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.recipients-table th, .recipients-table td {
  padding: 15px 20px;
}

.recipients-table th {
  background: #490a7d;
  color: #ffffff;
  font-weight: 700;
}

.recipients-table td {
  background-color: #ffffff;
  color: #3e3b3f;
  border-bottom: 1px solid #f0f0f0;
}

.recipients-table tr:nth-of-type(even) td {
  background-color: #f9f9f9;
}

.recipients-table tr:hover td {
  background-color: #f1f1f1;
  color: #34057b;
}

.recipients-table tr:hover td:first-child {
  border-left: 5px solid #34057b;
}

.recipients-table th:first-child,
.recipients-table td:first-child {
  border-left: none;
}

.recipients-table th:last-child,
.recipients-table td:last-child {
  border-right: none;
}

.medal-image {
  display: flex;
  justify-content: center;
}

.medal-image img{
  width: 500px;
  height: auto;
}

/* Statute */

.statute-wrapper {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 50px auto;
}

.statute-title {
  font-size: 48px;
  font-weight: 700;
  color: #1b1b29;
  text-align: center;
  margin-bottom: 30px;
}

.statute-toc {
  margin-bottom: 30px;
}

.statute-list {
  list-style: none;
  padding: 20px 0px;
}

.statute-list li {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 20px;
  cursor: pointer;
}

.statute-list a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s;
}

.statute-list a:hover {
  color: #34057b;
}

.statute-content {
  background-color: #fff;


}

.statute-section {
  margin-bottom: 30px;
}

.statute-subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #1b1b29;
  margin-bottom: 20px;
}

.statute-text {
  font-size: 20px;
  line-height: 1.6;
  color: #3e3b3f;
  padding-bottom: 20px;
}

.statute-list ul {
  padding-left: 20px;
}

.statute-list li ul {
  list-style-type: disc;
}

.statute-list li ul li {
  margin-bottom: 5px;
}

/* Members */

.members-wrapper {

  max-width: 1200px;
  margin: 0px auto 100px auto;
  text-align: center;
}

.members-title {
  font-size: 48px;
  font-weight: 700;
  color: #1b1b29;
  margin-bottom: 30px;
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.member-card {
  background: #fff;
  color: #3e3b3f;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.member-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #1b1b29;
}

.member-description {
  font-size: 18px;
  color: #3e3b3f;
  padding-bottom: 10px;
}

.member-year {
  font-size: 16px;
  color: #3e3b3f;
  font-weight: 600;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination a {
  color: #3e3b3f;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pagination a:hover {
  background-color: #34057b;
  color: #fff;
}

.pagination .active a {
  background-color: #921cbc;
  color: #fff;
}

.board-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  margin-bottom: 50px;
}

.board-member-card {
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 1240px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.board-member-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.board-member-image {
  width: 200px;
  height: 242px;
}

.board-member-content {
  padding: 20px;
}

.board-member-name {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1b1b29;
}

.board-member-description {
  font-size: 22px;
  color: #3e3b3f;
}

/* Publication */

.publications-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.publications-wrapper {
  max-width: 1280px;
  margin: 0px auto 100px auto;
  text-align: center;
}

.publication-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 400px;
  text-decoration: none;
  color: inherit;
  position: relative;
  transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.publication-card:hover .publication-image {
  transform: scale(1.05);
  transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.publication-card:hover .publication-title {
  color: #921cbc;
}

.publication-image {
  width: 100%;
  height: 150px;
  transition: transform 0.3s ease;
}

.publication-content {
  padding: 15px;
}

.publication-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1b1b29;

  transition: color 0.3s ease;
}

.publication-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.publication-footer {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}

.publication-detail {
  max-width: 1240px;
  margin: 20px auto 50px auto;

}

.publication-detail-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}


.publication-detail-title {
  font-size: 28px;
  font-weight: bold;
  margin: 20px 0px;
  color: #1b1b29;
}

.publication-detail-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1b1b29;
}

.publication-detail-date {
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
}

.p-20 {
  padding-bottom: 10px;
}

.publication-detail-content {
  font-size: 20px;
  line-height: 1.6;
  color: #3e3b3f;
}



/* Contacts */

.contacts-wrapper {
  padding: 50px 20px;
  max-width: 800px;
  margin: 50px auto;
}

.contacts-title {
  font-size: 48px;
  font-weight: 700;
  color: #1b1b29;
  text-align: center;
  margin-bottom: 30px;
}

.contacts-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.contact-icon {
  font-size: 36px;
  color: #921cbc;
  margin-right: 20px;
}

.contact-label {
  font-size: 20px;
  font-weight: 600;
  color: #1b1b29;
  margin-bottom: 5px;
}

.contact-info {
  font-size: 18px;
  color: #3e3b3f;
}

@media (max-width: 768px) {
  .contacts-wrapper {
      padding: 30px 10px;
  }

  .contacts-title {
      font-size: 36px;
  }

  .contact-card {
      padding: 15px;
  }

  .contact-icon {
      font-size: 28px;
      margin-right: 15px;
  }

  .contact-label {
      font-size: 18px;
  }

  .contact-info {
      font-size: 16px;
  }
}

/* Footer */

.footer {
  background: #3e3b3f;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo-img {
  height: 50px;
}

.footer-links {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #921cbc;
}

.footer-contact {
  max-width: 800px;
  line-height: 1.3;
}

.footer-contact p {
  margin: 15px 0;
}

.footer-copy {
  margin-top: 20px;
  font-size: 14px;
}
